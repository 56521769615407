<template>
  <div class="flower">
    <div class="slidesWrap">
      <div class="smallSlides">
        <div class="image" v-for="image in flower.images" :key="image.id">
          <img :src="image.url" alt="flower" draggable="false" @click="setActiveSlide(image)">
        </div>
      </div>
      <div class="activeSlide" v-if="activeSlide">
        <div>
          <zoom-on-hover :img-normal="activeSlide.url" scale="2"></zoom-on-hover>
        </div>
      </div>
    </div>
    <div class="flowerDesc">
      <div class="flowerName">
        {{flower.name}}
      </div>
      <div class="flowerDescription">
        {{flower.description}}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import zoomOnHover from '@/components/zoomOnHover.vue'
import '@/assets/zoomOnHover.css'
export default {
  name: 'flowerView',
  components: {
    zoomOnHover
  },
  data() {
    return {
      flower: {},
      activeSlide: null
    }
  },
  created() {
    this.getFlower()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    setActiveSlide(newSlide) {
      this.activeSlide = newSlide
    },
    getFlower() {
      axios.get(`https://flowers-olga.com/api/flowers/${this.$route.params.id}`).then(resp => {
        this.flower = resp.data
        this.activeSlide = this.flower?.images[0]
      })
    },
  }
}
</script>
<style scoped lang="scss">
.flower {
  padding: 40px;
  display: flex;
  justify-content: center;
  gap: 50px;
  .slidesWrap {
    display: flex;
    gap: 20px;
    .smallSlides {
      display: flex;
      flex-direction: column;
      gap: 20px;
      img {
        width: 150px;
      }
    }
    .activeSlide {
      width: 600px;
      img {
        width: 100%;
      }
    }
  }
  .flowerDesc {
    .flowerName {
      font-size: 40px;
      text-transform: uppercase;
    }
    .flowerDescription {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 1023px){
  .flower {
    flex-direction: column-reverse;
  }
  .slidesWrap {
    width: 100%;
    .smallSlides {
      width: 100%;
      img {
        width: 100% !important;
      }
    }
    .activeSlide {
      display: none;
    }
  }
}
</style>
