<template>
  <div class="zoom-on-hover" v-bind:class="{zoomed}" @touchstart="touchzoom"
       @mousemove="move" @mouseenter="zoom" @mouseleave="unzoom">
    <img class="normal" ref="normal" :src="imgNormal" alt="a"/>
    <img class="zoom" ref="zoom" :src="imgZoom || imgNormal" alt="a"/>
  </div>
</template>
<script>
export default {
  props: ["imgNormal", "imgZoom", "scale", "disabled"],
  data: function () {
    return {
      scaleFactor: 1,
      resizeCheckInterval: null,
      zoomed: false,
    }
  },
  methods: {
    pageOffset(el) {
  // -> {x: number, y: number}
  // get the left and top offset of a dom block element
  var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    y: rect.top + scrollTop,
    x: rect.left + scrollLeft
  }
},

touchzoom: function (event) {
      if (this.disabled) return
      this.move(event)
      this.zoomed = !this.zoomed
    },
    zoom: function () {
      if (!this.disabled) this.zoomed = true
    },
    unzoom: function () {
      if (!this.disabled) this.zoomed = false
    },
    move: function (event) {
      if (this.disabled || !this.zoomed) return
      const offset = this.pageOffset(this.$el)
      const zoom = this.$refs.zoom
      const normal = this.$refs.normal
      const relativeX = event.clientX - offset.x + window.pageXOffset
      const relativeY = event.clientY - offset.y + window.pageYOffset
      const normalFactorX = relativeX / normal.offsetWidth
      const normalFactorY = relativeY / normal.offsetHeight
      const x = normalFactorX * (zoom.offsetWidth * this.scaleFactor - normal.offsetWidth)
      const y = normalFactorY * (zoom.offsetHeight * this.scaleFactor - normal.offsetHeight)
      zoom.style.left = -x + "px"
      zoom.style.top = -y + "px"
    },
    initEventLoaded: function () {
      // emit the "loaded" event if all images have been loaded
      const promises = [this.$refs.zoom, this.$refs.normal].map(function (image) {
        return new Promise(function (resolve, reject) {
          image.addEventListener("load", resolve)
          image.addEventListener("error", reject)
        })
      })
      const component = this
      Promise.all(promises).then(function () {
        component.$emit("loaded")
      })
    },
    initEventResized: function () {
      const normal = this.$refs.normal
      let previousWidth = normal.offsetWidth
      let previousHeight = normal.offsetHeight
      const component = this
      this.resizeCheckInterval = setInterval(function () {
        if ((previousWidth != normal.offsetWidth) || (previousHeight != normal.offsetHeight)) {
          previousWidth = normal.offsetWidth
          previousHeight = normal.offsetHeight
          component.$emit("resized", {
            width: normal.width,
            height: normal.height,
            fullWidth: normal.naturalWidth,
            fullHeight: normal.naturalHeight
          })
        }
      }, 1000)
    }
  },
  mounted: function () {
    if (this.$props.scale) {
      this.scaleFactor = parseInt(this.$props.scale)
      this.$refs.zoom.style.transform = "scale(" + this.scaleFactor + ")"
    }
    this.initEventLoaded()
    this.initEventResized()
  },
  updated: function () {
    this.initEventLoaded()
  },
  beforeDestroy: function () {
    this.resizeCheckInterval && clearInterval(this.resizeCheckInterval)
  }
}
</script>