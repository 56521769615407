<template>
  <div class="navBar">
    <div class="navContent">
      <div class="logo" @click="$router.push('/')">
        <img :src="require('@/assets/logo.png')" alt="logo">
      </div>
      <div class="bottomWrap">
        <div class="links">
          <div class="link" @click="$router.push('/')">
            Home
          </div>
          <div class="link" @click="$router.push('/about')">
            About
          </div>
          <div class="link" @click="$router.push('/contacts')">
            Contacts
          </div>
        </div>
        <div class="user" @click="logOutUser">
          {{StateUserName ? 'Log Out' : ''}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'navBarComponent',
  components: {
  },
  data() {
    return {

    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['StateUserName'])
  },
  methods: {
    ...mapActions(["LogOut"]),
    async logOutUser() {
      await this.LogOut()
      await this.$router.push('/')
    }
  }
}
</script>
<style scoped lang="scss">
.navBar {
  background: #5bbf6f;
  height: 100px;
  color: #fff;
  .navContent {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    height: 60px;
    .logo {
      cursor: pointer;
      text-transform: uppercase;
      font-size: 30px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;

      }
    }
    .bottomWrap {
      padding-left: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .links {
      display: flex;
      gap: 50px;
      .link {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 20px;
      }
    }
    .user {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 1023px) {
  .navBar {
    height: 130px;
    .navContent {
      padding: 20px;
      height: 90px;
      .logo {
        align-self: flex-start;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 100px;
        }
      }
      .links {
        flex-wrap: wrap;
        align-items: center;
        gap: 20px
      }
    }
  }
}
</style>
