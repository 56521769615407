<template>
  <div class="login">
    <div class="formWrap">
      <div class="inputLabel">
        Login:
      </div>
      <input v-model="form.login" placeholder="login"/>
      <div class="inputLabel">
        Password:
      </div>
      <input type="password" v-model="form.password" placeholder="********"/>
      <div class="button" @click="login">
        LogIn
      </div>
<!--      <a href="" class="forgotPassword">-->
<!--        forgot password?-->
<!--      </a>-->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'LoginView',
  components: {
  },
  data() {
    return {
      form: {
        login: "",
        password: "",
      },
      message: '',

      showError: false
    }
  },
  mounted () {
    // this.register()
  },
  computed: {
  },
  methods: {
    ...mapActions(["LogIn"]),
    async login() {
      const User = {
        login: this.form.login,
        password: this.form.password
      };
      try {
        await this.LogIn(User);
        await this.$router.push('/admin')
      } catch (error) {
        this.showError = true
        this.message = error.response.data.message
      }
    },
  }
}
</script>
<style scoped lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

</style>
