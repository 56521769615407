import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

let url = 'https://flowers-olga.com/api'

export default new Vuex.Store({
  state: {
    username: null,
    tokens: null,
    roles: null
  },
  getters: {
    isAuthenticated: state => !!state.username,
    StateUserName: state => state.username,
    StateToken: state => state.tokens,
    StateRoles: state => state.roles
  },
  mutations: {
    setUserName(state, username){
      state.username = username
    },
    setTokens(state, tokens){
      state.tokens = tokens
    },
    logout(state){
      state.username = null
      state.tokens = null
      state.roles = null
    },
    setRoles(state, roles){
      state.roles = roles
    }
  },
  actions: {
    async LogIn({commit}, User) {
      await axios.post(`${url}/auth/sign-in`, {
        login: User.login,
        password: User.password
      }).then(resp => {
        commit('setTokens', resp.data.tokens)
        commit('setUserName', resp.data.userName)
        commit('setRoles', resp.data.userName)
      })
    },
    async LogOut({commit}){
      let user = null
      await commit('logout', user)
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
