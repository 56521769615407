<template>
  <div class="home">
    <div class="flowersWrap">
      <div class="flower" v-for="(flower, _) in flowers" :key="_">
        <div class="flowerContent" @click="$router.push(`/flower/${flower.id}`)">
          <div class="imagesWrap">
            <VueSlickCarousel :arrows="false" :dots="true">
              <div class="image" v-for="image in flower.images" :key="image.id">
                <img :src="image.url" alt="flower" draggable="false">
              </div>
            </VueSlickCarousel>
          </div>
          <div class="divider"></div>
          <div class="name">
            {{ flower.name }}
          </div>
          <div class="desc">
            {{ flower.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'homeView',
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      flowers: [],
    }
  },
  created() {
    this.getFlowers()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getFlowers() {
      axios.get(`https://flowers-olga.com/api/flowers`).then(resp => {
        this.flowers = resp.data.data
      })
    },
  }
}
</script>
<style scoped lang="scss">
.home {
  padding: 40px;
}
</style>
