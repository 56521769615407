<template>
  <div id="app">
    <div class="navigation">
      <navigation-component/>
    </div>
    <router-view/>
  </div>
</template>
<script>
import navigationComponent from "@/components/navigationComponent.vue";
export default {
  name: 'app',
  components: {
    navigationComponent
  },
  data() {
    return {

    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:wght@300;400;500&display=swap');
html, body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
#app {
  min-height: 100vh;
  font-family: Roboto, sans-serif;
}
input {
  font-family: Roboto, sans-serif;
  &::placeholder {
    font-family: Roboto, sans-serif;
  }
}

.inputLabel {
  width: 365px;
  color: #000;
  font-size: 16px;
  letter-spacing: 0;
  margin-bottom: 12px;
}
input, textarea {
  width: calc(100% - 18px * 2);
  font-size: 16px;
  margin-bottom: 12px;
  padding: 12px 16px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #ccc;
  color: #000;
  &::placeholder {
    color: #505050;
  }
}

.button {
  border-radius: 139px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  background: #5bbf6f;
  text-transform: uppercase;
  margin-top: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.flowersWrap {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #bdbdbd;
  border-bottom: none;
  &::v-deep img {
    user-select: none;
  }
  .flower {
    width: calc(25% - 1px);
    border-right: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    &:nth-child(4) {
      border-right: none;
    }
    .flowerContent {
      padding: 20px;
      .divider {
        width: 95%;
        height: 1px;
        background: #bdbdbd;
        margin: 40px auto 0;
      }
      .name {
        margin-top: 20px;
        font-size: 40px;
        margin-bottom: 10px;
      }
      .desc {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        height: 20px;
        white-space: nowrap;
      }
      .imagesWrap {
        height: 400px;
        overflow: hidden;
        .image {
          width: 400px;
          position: relative;
          img {
            width: 100%;
          }
          .deleteImageButton {
            cursor: pointer;
            position: absolute;
            top: 20px;
            left: 20px;
            padding: 10px;
            background: #ff7575;
          }
        }
      }
    }
  }
}
h1 {
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-size: 40px;
}
a {
  text-decoration: none;
  color: #000;
  &:hover {
    color: #5bbf6f;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px){
  .flowersWrap {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #bdbdbd;
    border-bottom: none;
    &::v-deep img {
      user-select: none;
    }
    .flower {
      width: calc(50% - 1px);
      border-right: 1px solid #bdbdbd;
      border-bottom: 1px solid #bdbdbd;
      &:nth-child(2) {
        border-right: none;
      }
      .flowerContent {
        padding: 20px;
        .divider {
          width: 95%;
          height: 1px;
          background: #bdbdbd;
          margin: 40px auto 0;
        }
        .name {
          margin-top: 20px;
          font-size: 40px;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 16px;
        }
        .imagesWrap {
          height: 400px;
          .image {
            width: 400px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1023px){
  .flowersWrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border: 1px solid #bdbdbd;
    border-bottom: none;
    &::v-deep img {
      user-select: none;
    }
    .flower {
      width: calc(100% - 1px);
      border-right: 1px solid #bdbdbd;
      border-bottom: 1px solid #bdbdbd;
      &:nth-child(4) {
        border-right: 1px solid #bdbdbd;
      }
      .flowerContent {
        padding: 20px;
        .divider {
          width: 95%;
          height: 1px;
          background: #bdbdbd;
          margin: 40px auto 0;
        }
        .name {
          margin-top: 20px;
          font-size: 40px;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 16px;
        }
        .imagesWrap {
          height: 300px;
          .image {
            width: 400px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>