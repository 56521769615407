<template>
  <div class="admin">
    <div class="createWrap">
      <div class="button createBtn" @click="openCreate">
        create new flower
      </div>
    </div>
    <div class="flowersWrap">
      <div class="flower" v-for="(flower, _) in flowers" :key="_">
        <div class="flowerContent">
          <div class="imagesWrap">
            <VueSlickCarousel :arrows="false" :dots="true">
              <div class="image" v-for="image in flower.images" :key="image.id">
                  <img :src="image.url" alt="flower" draggable="false">
                  <div class="button deleteImageButton" @click="deleteFlowerImage(flower.id, image.id)">
                    delete image
                  </div>
              </div>
            </VueSlickCarousel>
          </div>
          <div class="divider"></div>
          <div class="name">
            {{ flower.name }}
          </div>
          <div class="desc">
            {{ flower.description }}
          </div>
          <div class="button" @click="openEdit(flower)">
            edit
          </div>
        </div>
      </div>
    </div>
    <div class="createModalWrap" v-if="createModal">
      <div class="createModal">
        <div class="close">
          <img :src="require('@/assets/svg/closeGreen.svg')" alt="close" @click="createModal = false">
        </div>
        <p class="modalTitle">
          Create new flower
        </p>

        <div class="formWrap">
          <div class="inputLabel">
            Name:
          </div>
          <input v-model="form.name" placeholder="name"/>
          <div class="inputLabel">
            Description:
          </div>
          <input type="text" v-model="form.description" placeholder="description"/>

          <div class="dropWrap">

            <div class="dragAndDrop" @dragover="dragover" @drop="drop">
              <input type="file" id="assetsFieldHandle" @change="onChange" ref="file" multiple accept=".pdf,.jpg,.jpeg,.png">
              <label for="assetsFieldHandle">
                <div class="dragAndDropBody">
                  <span v-if="file" class="selectedFile" @click="remove">
                  {{file.name}}
                  <img :src="require('/src/assets/svg/close.svg')" alt="" draggable="false">
                </span>
                  <br>
                </div>
              </label>
            </div>
          </div>
          <div class="button" @click="sendFile">
            Create
          </div>
          <!--      <a href="" class="forgotPassword">-->
          <!--        forgot password?-->
          <!--      </a>-->
        </div>
      </div>
    </div>
    <div class="editModalWrap createModalWrap" v-if="editModal">
      <div class="createModal">
        <div class="close">
          <img :src="require('@/assets/svg/closeGreen.svg')" alt="close" @click="editModal = false">
        </div>
        <p class="modalTitle">
          Edit flower {{editObj.name}}
        </p>

        <div class="formWrap">
          <div class="inputLabel">
            Name:
          </div>
          <input v-model="editObj.name" placeholder="name"/>
          <div class="inputLabel">
            Description:
          </div>
          <input type="text" v-model="editObj.description" placeholder="description"/>

          <div class="dropWrap">

            <div class="dragAndDrop" @dragover="dragover" @drop="drop">
              <input type="file" id="assetsFieldHandleEdit" @change="onChangeEdit" ref="fileEdit" multiple accept=".pdf,.jpg,.jpeg,.png">
              <label for="assetsFieldHandleEdit">
                <div class="dragAndDropBody">
                  <span v-if="fileEdit" class="selectedFile" @click="remove">
                  {{file.name}}
                  <img :src="require('/src/assets/svg/close.svg')" alt="" draggable="false">
                </span>
                  <br>
                </div>
              </label>
            </div>
          </div>
          <div class="button" @click="sendFileEdit">
            Change
          </div>
          <div class="button red" @click="deleteFlower">
            Delete
          </div>
          <!--      <a href="" class="forgotPassword">-->
          <!--        forgot password?-->
          <!--      </a>-->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'AdminView',
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      flowers: [],
      files: [],
      file: null,
      fileEdit: null,
      createModal: false,
      editModal: false,
      editObj: null,
      form: {
        name: "",
        description: "",
        images: []
      },
    }
  },
  created() {
    this.getFlowers()
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['StateToken']),
  },
  methods: {
    deleteFlowerImage(flowerId, imageId) {
      axios.delete(`https://flowers-olga.com/api/flowers/${flowerId}/image/${imageId}`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then( () => {
        this.getFlowers()
      })
    },
    getFlowers() {
      axios.get(`https://flowers-olga.com/api/flowers`, {
        headers: { authorization: `Bearer ${this.StateToken.access}`}
      }).then(resp => {
        this.flowers = resp.data.data
      })
    },
    openCreate() {
      this.createModal = true
    },
    openEdit(flower) {
      this.editObj = flower
      this.editModal = true
    },
    deleteFlower() {
      const headers = { 'authorization': `Bearer ${this.StateToken.access}` };
      axios.delete(`https://flowers-olga.com/api/flowers/${this.editObj.id}`, {headers}).then( () => {
        this.editModal = false
        this.getFlowers()
      })
    },

    sendFile(e) {
      e.preventDefault()
      const formData = new FormData();
      formData.append('name', this.form.name)
      formData.append('description', this.form.description)
      for (let i = 0; i < this.file.length; i++) {
        formData.append('imagesFiles', this.file[i]); // 'imagesFiles[]' - назва поля для кожного файлу
      }

      const headers = { 'Content-Type': 'multipart/form-data',  'authorization': `Bearer ${this.StateToken.access}` };
      axios.post('https://flowers-olga.com/api/flowers', formData, {headers}).then(resp => {
        this.file = null
        this.createModal = false
        this.getFlowers()
      })
    },
    sendFileEdit(e) {
      e.preventDefault()
      const formData = new FormData();
      console.log(this.fileEdit)
      formData.append('name', this.editObj.name)
      formData.append('description', this.editObj.description)
      if (this.fileEdit) {
        for (let i = 0; i < this.fileEdit.length; i++) {
          formData.append('imagesFiles', this.fileEdit[i]); // 'imagesFiles[]' - назва поля для кожного файлу
        }
      }
      else {
        formData.append('imagesFiles', [])
      }

      const headers = { 'Content-Type': 'multipart/form-data',  'authorization': `Bearer ${this.StateToken.access}` };
      axios.put(`https://flowers-olga.com/api/flowers/${this.editObj.id}`, formData, {headers}).then(resp => {
        this.fileEdit = null
        this.editModal = false
        this.getFlowers()
      })
    },
    remove(e) {
      e.preventDefault()
      this.file = null
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
    onChange() {
      this.file = this.$refs.file.files
      // console.log(this.file)
    },
    onChangeEdit() {
      this.fileEdit = this.$refs.fileEdit.files
      // console.log(this.file)
    },
  }
}
</script>
<style scoped lang="scss">
.admin {
  padding: 40px;
  .createModalWrap {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .createModal {
      position: relative;
      .close {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
        img {
          height: 40px;
          width: 40px;
        }
      }
      padding: 40px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      width: 800px;
      height: 600px;
      background: #fff;
      .modalTitle {
        font-size: 40px;
        margin: 0 0 40px;
      }
    }
  }
}
</style>
