<template>
  <div class="contact">
    <h1>
      Contacts
    </h1>
    email <a href="mailto:olganorw@gmail.com">olganorw@gmail.com</a>
    <br>
    telefon: <a href="tel:+4790119839">47 90 11 98 39</a>(only sms)
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'contactView',
  components: {
  },
  data() {
    return {

    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
.contact {
  padding: 40px;
}
</style>
