<template>
  <div class="about">
    <h1>
      About
    </h1>

    Hello! My name is Olga Smilyak, I live in the north of Norway, in the Flakstad kommune.
    <br>
    <br>
    Welcome to a site dedicated to beautiful flowering plants such as Streptocarpus and other house plants. All photos were taken by me personally.
    <br>
    <br>
    Dear guests, I present my collection of Streptocarpus and other plants.
    <br>
    <br>
    Growing Streptocarpus at home is not difficult, but they need proper care, watering and light, and a little inspiration.
    <br>
    <br>
    Indoor plants are a source of inspiration and joy. Whether it is wind, rain, snow, frost outside the window, it is always summer in our home garden. My dear visitors, I wish you that your home will be filled with charming plants, grateful and patient, ready to bloom almost non-stop. Let your indoor gardens become the subject of your love, admiration and pride!

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'aboutView',
  components: {
  },
  data() {
    return {

    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
.about {
  padding: 40px;
}
</style>
