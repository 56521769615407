import Vue from 'vue'
import store from '../store/index';
import VueRouter from 'vue-router'
import loginView from "@/views/loginView.vue";
import adminView from "@/views/adminView.vue";
import homeView from "@/views/homeView.vue";
import aboutView from "@/views/aboutView.vue";
import contactView from "@/views/contactView.vue";
import flowerView from "@/views/flowerView.vue";



Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'home',
    component: homeView,
  },
  {
    path: '/about',
    name: 'about',
    component: aboutView,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: contactView,
  },
  {
    path: '/flower/:id',
    name: 'flower',
    component: flowerView,
  },
  {
    path: '/login',
    name: 'login',
    component: loginView,
    meta: { guest: true }
  },
  {
    path: '/admin',
    name: 'admin',
    component: adminView,
    meta: { requireAdmin: true }
  },
  {
    path: "*",
    name: '404',
    redirect: '/',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAdmin)) {
    if (store.getters.StateRoles === null) {
      next('/login')
    }
    else if (store.getters.StateRoles === "admin") {
      next();
      return;
    }
      next();
  }
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.StateRoles === null) {
      next()
    }
    else if (store.getters.StateRoles === "admin") {
      next('/admin');
      return;
    }
      next();
  }
  else {
    next()
  }
});

export default router